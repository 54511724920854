import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Prayagraj</title>
<meta name="description" content="Top website designer in Prayagraj. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website designing company in Prayagraj, website developer in Prayagraj, senior website developer, web development company in Prayagraj, website maker in Prayagraj, website design company, pankaj web designer, pankj, freelance website designer, website designer in Prayagraj, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Prayagraj" />
<meta property="og:description" content="Top Website Designer In Prayagraj. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Prayagraj" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-prayagraj"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.jpg"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.jpg" />
<meta name="twitter:title" content="Pankaj | Website Designer In Prayagraj" />
<meta name="twitter:description" content="Top website designer in Prayagraj. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-prayagraj" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer in Prayagraj</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Prayagraj</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Are you in search of a professional website designer in Prayagraj who can transform your ideas into a stunning online presence? Look no further! I’m Pankaj, a dedicated website designer focused on creating visually appealing and functional websites that help you achieve your business goals and connect with your audience effectively.
<h3>Why Choose Pankaj for Your Website Design?</h3>
<ol>
  <li><strong>Custom Designs Tailored to Your Vision</strong>
With years of experience in website design, I specialize in crafting bespoke solutions that reflect your brand's identity. Whether you need an elegant corporate site, a dynamic e-commerce platform, or a personal blog, I ensure that every aspect of your website aligns with your specific requirements.</li>
  <li><strong>SEO-Optimized Websites for Better Visibility</strong>
A beautiful website must also be discoverable. I implement essential SEO practices in all my designs, ensuring that your website ranks higher on search engines like Google. This leads to increased visibility and more opportunities to attract potential customers.</li>
  <li><strong>Comprehensive Website Design Services</strong>
I offer a wide range of services, including website development, redesigns, and e-commerce solutions. My expertise with popular CMS platforms like WordPress allows you to manage and update your content easily once your site is live.</li>
  <li><strong>Transparent Pricing and Ongoing Support</strong>
I believe in clear communication and transparent pricing. After discussing your project needs, I provide a detailed quote, so there are no surprises. My commitment to client satisfaction ensures that I'm here to support you even after your website goes live.</li>
</ol>
<h3>Services Offered</h3>
<ul>
  <li><strong>Custom Website Design &amp; Redesign</strong>
Unique, modern designs that enhance user engagement and provide a seamless experience while representing your brand identity effectively.</li>
  <li><strong>E-commerce Website Development</strong>
Secure and user-friendly e-commerce platforms designed to maximize conversions and offer a smooth shopping experience for your customers.</li>
  <li><strong>Corporate and Personal Websites</strong>
Professionally designed websites that effectively showcase your business or personal projects, engaging visitors and clearly communicating your goals.</li>
  <li><strong>SEO and Digital Marketing Services</strong>
Basic SEO practices are included in all designs, with options for advanced strategies through collaboration with trusted marketing partners.</li>
</ul>
<h3>Get Started Today</h3>
Ready to enhance your online presence? Contact me today to discuss your vision, and let’s work together to create a website that truly represents your brand in the digital world.
<h2>Frequently Asked Questions</h2>

<h4>What types of websites can Pankaj design in Prayagraj?</h4>
<p>Pankaj specializes in designing a variety of websites, including corporate sites, e-commerce platforms, personal blogs, and portfolios. Each website is customized to meet your specific needs and goals.</p>

<h4>Why should I choose Pankaj as my website designer?</h4>
<p>Pankaj offers high-quality, responsive, and SEO-friendly websites crafted with meticulous attention to detail. His extensive experience ensures that each project effectively communicates your brand identity while providing a seamless user experience.</p>

<h4>Does Pankaj provide SEO services for my website?</h4>
<p>Yes, Pankaj incorporates basic SEO practices in all his website designs to enhance visibility on search engines. For more advanced SEO strategies, he can collaborate with trusted partners who specialize in digital marketing.</p>

<h4>How much does it cost to design a website with Pankaj?</h4>
<p>The cost of designing a website varies based on several factors, including the project's complexity, the number of pages, and any additional features you may want. Pankaj provides project-specific quotes after discussing your requirements and budget.</p>

<h4>Can I update my website content after it’s launched?</h4>
<p>Absolutely! Pankaj builds websites using user-friendly CMS platforms like WordPress, allowing you to easily manage and update your content once the site is live. He also provides training and documentation to assist you in making updates.</p>

<h4>What if I need help with my website after it’s live?</h4>
<p>Pankaj offers ongoing maintenance and support for all his clients. This includes troubleshooting, updates, and performance improvements to ensure your website remains secure and effective over time.</p>

<h4>How long does it take to complete a website design project?</h4>
<p>The timeline for completing a website design project varies based on its complexity and specific requirements. After discussing your project, Pankaj will provide a realistic timeline, which typically ranges from a few weeks to a couple of months.</p>

<h4>How can I get in touch with Pankaj for my website design project?</h4>
<p>You can reach out to Pankaj through the contact form on this website or by using the provided contact information. He will be happy to discuss your project and help you create a website that meets your needs.</p>

          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
