import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Hyderabad</title>
<meta name="description" content="Top website designer in Hyderabad. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website developers in hyderabad, website design hyderabad, website design company hyderabad, web developers in hyderabad, website creators in hyderabad, website makers in hyderabad, website designer in hyderabad, website development hyderabad, website designers in hyderabad india, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Hyderabad" />
<meta property="og:description" content="Top Website Designer In Hyderabad. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Hyderabad" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-hyderabad"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.jpg"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.jpg" />
<meta name="twitter:title" content="Pankaj | Website Designer In Hyderabad" />
<meta name="twitter:description" content="Top website designer in Hyderabad. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-hyderabad" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer In Hyderabad</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Hyderabad</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Looking for a skilled website designer in Hyderabad? With over a decade of experience, I offer comprehensive web design services tailored to businesses of all types. My designs are visually appealing, user-friendly, and optimized for both SEO and mobile compatibility. I specialize in creating e-commerce sites, corporate websites, portfolios, blogs, and landing pages, ensuring they align with your brand and business goals.
<h3>Why Choose My Web Design Services in Hyderabad?</h3>
<ul>
  <li><strong>Responsive Design</strong>: All websites I design are fully optimized for mobile, tablet, and desktop devices.</li>
  <li><strong>SEO Integration</strong>: I follow SEO best practices to help your website rank on Google, bringing organic traffic to your business.</li>
  <li><strong>Customization</strong>: I design each site to reflect your unique brand and meet specific business needs.</li>
  <li><strong>Maintenance &amp; Support</strong>: Post-launch, I offer ongoing support, maintenance, and updates to keep your website performing at its best.</li>
  <li><strong>User-Friendly CMS</strong>: I work with platforms like WordPress, enabling you to make content updates easily.</li>
</ul>
    <h2>Frequently Asked Questions</h2>
    <div className="accordion" id="faqAccordion">

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    What types of websites do you design?
                </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    I design various websites, including e-commerce platforms, corporate sites, portfolios, blogs, and landing pages, customized to suit your business needs.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    How long does it take to design a website?
                </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    Timelines vary based on the project’s complexity. Standard sites typically take 2-4 weeks, while complex sites like e-commerce platforms may take 4-8 weeks. I’ll provide a detailed timeline after understanding your requirements.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Will my website be SEO-friendly?
                </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    Yes, I integrate SEO best practices into each design, ensuring your site is optimized for search engines with fast loading times, keyword optimization, and mobile responsiveness.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Is my website going to be mobile-friendly?
                </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    Absolutely! All websites I design are fully responsive, providing a seamless experience on mobile, tablet, and desktop devices.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Do you offer redesign services for existing websites?
                </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    Yes, I provide redesign services to update your website’s look, functionality, and SEO performance, helping it align with your brand and become more user-friendly.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    What if I need help after my website goes live?
                </button>
            </h2>
            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    I offer ongoing maintenance and support plans to keep your site updated, secure, and running smoothly. This includes regular updates, backups, and troubleshooting.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    How much does it cost to design a website?
                </button>
            </h2>
            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    Pricing depends on the website’s complexity and specific requirements. Once we discuss your project, I’ll provide a detailed quote. I aim to offer high-quality service at competitive rates.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingEight">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                    Can I make changes to my website after it’s launched?
                </button>
            </h2>
            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    Yes! I design websites with user-friendly CMS platforms like WordPress, making it easy for you to update content. For complex updates, I’m here to assist.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingNine">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                    What do I need to provide to start the project?
                </button>
            </h2>
            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    To get started, I’ll need details about your business, target audience, design preferences, and specific functionalities. I’ll guide you through each step to clarify your needs.
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="headingTen">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                    Do you provide digital marketing or branding services?
                </button>
            </h2>
            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                    While I focus on website design, I offer basic digital marketing and SEO services. For advanced needs, I can recommend trusted partners or collaborate with your existing marketing team.
                </div>
            </div>
        </div>

    </div>
          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
