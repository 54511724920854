import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Indore</title>
<meta name="description" content="Top website designer in Indore. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="web development company in indore, website design company in indore, web designing company in indore, website developer in indore, website maker in indore, website designer in indore, web designer in indore, best website developer in indore, best web design company in indore, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Indore" />
<meta property="og:description" content="Top Website Designer In Indore. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Indore" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-indore"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.jpg"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.jpg" />
<meta name="twitter:title" content="Pankaj | Website Designer In Indore" />
<meta name="twitter:description" content="Top website designer in Indore. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-indore" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer in Indore</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Indore</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Looking for a professional website designer in Indore who understands your business needs? You’re in the right place! I’m Pankaj, an experienced website designer, dedicated to creating responsive, SEO-optimized websites that not only look stunning but also drive results.
<h3>Why Choose Pankaj for Your Website Design?</h3>
<ol>
  <li><strong>Unbeatable Quality and Flexibility</strong>
My websites are crafted with attention to detail, ensuring high-quality visuals, functionality, and usability across devices. Whether it's an e-commerce platform, a corporate site, or a personal portfolio, each website is fully responsive and adaptable to mobile and desktop devices.</li>
  <li><strong>SEO-Ready for Easy Google Ranking</strong>
I focus on building SEO-friendly websites that make it easy for your business to be found on Google. From optimized code to thoughtful layout and speed, every detail is fine-tuned to boost your site’s performance in search engine rankings.</li>
  <li><strong>Comprehensive Services to Fit Every Need</strong>
I offer end-to-end solutions, from new website development to redesigns and ongoing maintenance. I work with popular CMS platforms like WordPress, allowing you to easily manage and update your website post-launch. Additionally, I offer basic digital marketing and SEO services, and can partner with experts if you need more advanced strategies.</li>
  <li><strong>Personalized Support and Transparent Pricing</strong>
I understand that every business has unique requirements and budgets. That’s why I offer project-specific timelines and cost estimates, ensuring you get the most value for your investment. I am always here to provide support, maintenance, and guidance, even after your website is live.</li>
</ol>
<h3>Services Offered</h3>
<ul>
  <li><strong>Website Design &amp; Redesign</strong>
Fresh, modern designs that reflect your brand’s identity and goals, with the option to revamp outdated sites.</li>
  <li><strong>SEO &amp; Digital Marketing</strong>
Basic SEO and digital marketing to enhance your site’s visibility, with advanced options available through trusted partners.</li>
  <li><strong>E-commerce Sites</strong>
Functional, secure e-commerce websites designed to maximize conversions and provide a seamless user experience.</li>
  <li><strong>Corporate &amp; Portfolio Websites</strong>
Tailored corporate sites and portfolios that communicate your message clearly and effectively to potential clients and customers.</li>
</ul>
<h3>Get in Touch</h3>
Ready to elevate your online presence? Contact me today to discuss your project and let’s start building a website that truly represents your brand in the digital world.
<h2>Frequently Asked Questions</h2>

<h4>What services does Pankaj offer as a website designer in Indore?</h4>
<p>Pankaj offers comprehensive website design services, including new website development, website redesign, e-commerce site creation, corporate and portfolio website design, SEO optimization, and basic digital marketing. He also provides ongoing maintenance and support to ensure your site remains up-to-date and functional.</p>

<h4>Why should I choose Pankaj for my website design?</h4>
<p>Pankaj is known for delivering high-quality, responsive, and SEO-friendly websites tailored to each client’s specific needs. He ensures every project is completed with attention to detail, offering transparent pricing and a commitment to ongoing support. His sites are designed to perform well on search engines, maximizing visibility and engagement.</p>

<h4>Does Pankaj provide SEO services for websites?</h4>
<p>Yes, Pankaj offers basic SEO services to improve your site’s visibility on search engines. If you require advanced SEO and digital marketing strategies, he can collaborate with trusted partners to deliver these specialized services.</p>

<h4>How does Pankaj determine the cost of a website design project?</h4>
<p>Pankaj provides project-specific pricing based on the complexity and requirements of each project. Factors such as design complexity, number of pages, and additional features like e-commerce integration can influence the final cost. He offers a transparent estimate after understanding your goals and budget.</p>

<h4>Can I update my website content after it is launched?</h4>
<p>Yes, Pankaj builds websites on user-friendly CMS platforms like WordPress, which enables clients to manage and update their content easily after the site is live. He also offers training and documentation if needed to help you navigate the updates independently.</p>

<h4>What if I need help after my website goes live?</h4>
<p>Pankaj provides ongoing maintenance and support to all his clients. This includes updates, troubleshooting, and performance optimizations as required. He is dedicated to ensuring your website stays secure, functional, and aligned with your evolving business needs.</p>

<h4>How long does it take to complete a website design project?</h4>
<p>The project timeline varies depending on the scope and complexity of the website. After discussing your project’s requirements, Pankaj provides an estimated timeline. Generally, projects can range from a few weeks for a basic website to a few months for more complex sites with multiple features.</p>


          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
